import { useState, useEffect, useMemo } from "react"
import { getImagePath } from "@/utils/helpers"

const WATERMARK_SRC = "/images/logo/logo_stroke.svg"

export type UseWatermarkReturnType = {
  src: string | null
  sizeImg: {
    height: number
    width: number
  }
}

export const useWatermark = (initialSrc: string): UseWatermarkReturnType => {
  const [src, setSrc] = useState<string | null>(null)
  const canvas = useMemo(() => document.createElement("canvas"), [])
  const [sizeImg, setSizeImg] = useState<{
    width: number
    height: number
  }>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const original = new window.Image()

    original.crossOrigin = "anonymous"
    original.src = getImagePath(initialSrc)
    original.onload = () => {
      canvas.width = original.naturalWidth
      canvas.height = original.naturalHeight

      const ctx = canvas.getContext("2d")

      if (!!ctx) {
        ctx.drawImage(original, 0, 0)

        const watermark = new window.Image()
        watermark.src = WATERMARK_SRC
        watermark.onload = () => {
          const w = canvas.width / 2
          const h = ((canvas.width / 2) * watermark.height) / watermark.width

          ctx.globalAlpha = 0.3
          ctx.drawImage(
            watermark,
            canvas.width / 2 - w / 2,
            canvas.height / 2 - h / 2,
            w,
            h,
          )

          const dataUrl = canvas.toDataURL()

          setSizeImg({
            width: canvas.width,
            height: canvas.height,
          })
          setSrc(dataUrl)
        }
      }
    }
  }, [initialSrc, canvas])

  return { src, sizeImg }
}
