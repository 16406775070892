import React, { FC, memo, MouseEvent, TouchEvent, useCallback, useEffect, useRef, useState } from "react";
import { CSSProperties, cx } from "@linaria/core";
import { cssIsZoomed, StyledOriginalImage, StyledZoomContainer } from "@/components/Products/parts";
import { ZoomImagePropsType } from "@/components/Products/parts/Media/Gallery/ZoomImage/types";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
const SCALE = 2;
const INITIAL_SCALE = 1;
const INITIAL_POSITION = {
  x: 0,
  y: 0
};
const ZoomImage: FC<ZoomImagePropsType> = memo(({
  src,
  onChangeZoom,
  isActive
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<typeof INITIAL_POSITION>(INITIAL_POSITION);
  const [scale, setScale] = useState<number>(INITIAL_SCALE);
  const reset = useCallback(() => {
    setScale(INITIAL_SCALE);
    setPosition(INITIAL_POSITION);
  }, []);
  const updatePosition = ({
    posY,
    posX
  }: {
    posX: number;
    posY: number;
  }) => {
    const container = containerRef.current;
    setScale(SCALE);
    if (!container) return;
    const {
      left: offsetLeft,
      top: offsetTop,
      height,
      width
    } = container.getBoundingClientRect();
    const x = (50 - Math.min(Math.max(posX - offsetLeft, 0), width) / width * 100) / 2;
    const y = (50 - Math.min(Math.max(posY - offsetTop, 0), height) / height * 100) / 2;
    setPosition({
      x,
      y
    });
  };
  const clickHandle = (e: MouseEvent) => {
    console.log("clickHandle");
    setIsZoomed(prevZoomed => {
      if (prevZoomed) {
        reset();
      } else {
        updatePosition({
          posX: e.clientX,
          posY: e.clientY
        });
      }
      return !prevZoomed;
    });
  };
  const touchStartHandle = (e: TouchEvent) => {
    e.preventDefault();
    console.log("touchStartHandle");
    updatePosition({
      posX: e.touches[0].clientX,
      posY: e.touches[0].clientY
    });
    e.stopPropagation();
  };
  const moveHandle = (e: MouseEvent) => {
    e.preventDefault();
    if (!isZoomed) return;
    updatePosition({
      posX: e.clientX,
      posY: e.clientY
    });
    e.stopPropagation();
  };
  const touchMoveHandle = (e: TouchEvent) => {
    console.log("touchMoveHandle");
    e.preventDefault();
    if (!isZoomed) return;
    updatePosition({
      posX: e.touches[0].clientX,
      posY: e.touches[0].clientY
    });
    e.stopPropagation();
  };
  const touchEndHandle = (e: TouchEvent) => {
    console.log("touchEndHandle");
    e.preventDefault();
    reset();
    e.stopPropagation();
  };
  const transform: CSSProperties = {
    transform: `scale(${scale}) translate(${position.x}%, ${position.y}%)`
  };
  useEffect(() => {
    if (!!onChangeZoom) {
      onChangeZoom(isZoomed);
    }
  }, [isZoomed]);
  useEffect(() => {
    if (!isActive) {
      reset();
    }
  }, [isActive]);
  return <StyledZoomContainer ref={containerRef} className={cx(isZoomed && cssIsZoomed)} onClick={clickHandle} onMouseMove={moveHandle} onTouchMove={touchMoveHandle} onTouchStart={touchStartHandle} touchEndHandle={touchEndHandle}>
        <StyledOriginalImage ref={imageRef} style={{
      ...transform
    }}>
          <EntityImage imagePath={src} objectFit={"contain"} quality={100} priority={false} loading={"lazy"} layout={"fill"} withPreloader={true} isStatic />
        </StyledOriginalImage>
      </StyledZoomContainer>;
});
ZoomImage.displayName = "ZoomImage";
export default ZoomImage;