import React, { FC, memo } from "react";
import { cx } from "@linaria/core";
import { StyledCounterError } from "./StyledCounter";
import { cssIsActive } from "@/styles/utils/Utils";
import { ErrorMessageContainer, ErrorMessageDefault } from "@/ui/Typography/Typography";
export type ErrorPropsType = {
  isShow: boolean;
  message: string;
};
export const Error: FC<ErrorPropsType> = memo(({
  isShow,
  message
}) => {
  return <StyledCounterError className={cx(isShow && cssIsActive)}>
      <ErrorMessageContainer>
        <ErrorMessageDefault dangerouslySetInnerHTML={{
        __html: message
      }} />
      </ErrorMessageContainer>
    </StyledCounterError>;
});
Error.displayName = "Error";