import { FC, memo } from "react"
import { useQuery } from "react-query"
import { ProductsSlider } from "../../Slider/ProductsSlider"
import { fetchProductsList } from "@/api/productsAPI"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"

export type KitPartProductsPropsType = {
  ids: string[]
  slidesPerViewStart?: number
}

export const KitPartProducts: FC<KitPartProductsPropsType> = memo(
  ({ ids, slidesPerViewStart = 4 }) => {
    const { data, isFetching } = useQuery(["kitParentsProducts", ids], () =>
      ids.length > 0
        ? fetchProductsList({
            uuids: ids.join(","),
          })
        : null,
    )

    return (
      <>
        {isFetching && <BaseLoader />}
        <ProductsSlider
          products={data || []}
          variantArrow={VARIANT_ARROW.ARROW}
          placementArrow={PLACEMENT_ARROW.HORIZONTAL}
          responsiveExtends={{
            [getBreakpointVal(breakpoints.lg)]: {
              slidesPerView: slidesPerViewStart,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          disabledOnMobile
        />
      </>
    )
  },
)

KitPartProducts.displayName = "KitPartProducts"
