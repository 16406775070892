import { FC } from "react"
import { useSwiperSlide } from "swiper/react"
import { StyledGalleryItem } from "@/components/Products/parts"
import { PanPinch } from "@/components/Products/parts/Media/Gallery/PanPinch"
import {
  ZoomImage,
  ZoomImagePropsType,
} from "@/components/Products/parts/Media/Gallery/ZoomImage"
import { useWatermark } from "@/hooks/watermark"
import { useWindowSize } from "@/hooks/windowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { getImagePath } from "@/utils/helpers"

const GalleryItem: FC<Omit<ZoomImagePropsType, "isActive">> = ({
  src,
  onChangeZoom,
}) => {
  const { src: watermarkedSrc } = useWatermark(getImagePath(src))
  const slide = useSwiperSlide()

  const { width } = useWindowSize()

  if (!watermarkedSrc || width === undefined) {
    return null
  }

  const isLessMd = width <= getBreakpointVal(breakpoints.md)

  return (
    <StyledGalleryItem>
      {isLessMd ? (
        <PanPinch src={watermarkedSrc} />
      ) : (
        <ZoomImage
          src={watermarkedSrc}
          onChangeZoom={onChangeZoom}
          isActive={slide.isActive}
        />
      )}
    </StyledGalleryItem>
  )
}

export { GalleryItem }
