import { FC, memo, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { cssBoxActive, cssBoxDisabled, cssBoxImage, cssBoxText, StyledVariations, VariationsBox, VariationsBoxInner, VariationsBoxList, VariationsSelectedName } from "./StyledVariations";
import { getCurrentSpecification } from "@/hooks/product/helpers";
import { UseProductReturnType } from "@/hooks/product/types";
import { useAppSelector } from "@/hooks/redux";
import { colors } from "@/styles/utils/vars";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Icon } from "@/ui/Icon";
import { ComponentLoader } from "@/ui/Loaders/ComponentLoader/ComponentLoader";
import { Select } from "@/ui/Select/Select";
import { Typography } from "@/ui/Typography/Typography";
import { ROUTES } from "@/utils/constants";
export type VariationsPropsType = Pick<UseProductReturnType, "variation" | "uuid">;
const FROM_VISIBLE_SELECT = 7;
const Variations: FC<VariationsPropsType> = memo(({
  uuid,
  variation = {}
}) => {
  const [isFetchingVariation, setIsFetchingVariation] = useState<boolean>(false);
  const specification = useAppSelector(({
    cart
  }) => cart.specification);
  const router = useRouter();
  const models = useMemo(() => variation.model || [], [variation.model]);
  useEffect(() => {
    setIsFetchingVariation(false);
  }, [uuid]);
  return <>
      <StyledVariations>
        {models.length >= FROM_VISIBLE_SELECT ? <>
            <Select ariaLabel={variation.selected?.paramName || "Вариация"} initialValue={models.find(v => v.uuid === uuid)?.uuid || undefined} items={models.map(v => {
          return {
            name: v.name,
            value: v.uuid,
            disabled: !v.totalQty,
            layout: <VariationsBoxInner className={cx(!v.totalQty && cssBoxDisabled)}>
                      <EntityImage imagePath={v.mainImage || undefined} width={40} height={40} />
                      <Typography variant={"span"}>{v.name}</Typography>
                      {Object.keys(getCurrentSpecification({
                uuid: v.uuid,
                specification: specification
              }) || {}).length > 0 && <>
                          <Icon NameIcon={"Check"} fill={colors.green} />
                        </>}
                    </VariationsBoxInner>
          };
        })} variant={"default"} isVisibleLayout onSelectValue={value => {
          const selectedVariation = models.find(v => v.uuid === value);
          if (!!selectedVariation) {
            if (selectedVariation.uuid !== uuid) {
              setIsFetchingVariation(true);
              void router.replace(`${ROUTES.product}/${selectedVariation.alias}`, undefined, {
                scroll: true
              });
            }
          }
        }} isFetching={isFetchingVariation} />
          </> : <>
            <VariationsSelectedName>
              {variation.selected?.paramName}:{" "}
              <Typography variant={"span"}>
                {variation.selected?.name}
              </Typography>
            </VariationsSelectedName>
            <VariationsBoxList>
              {isFetchingVariation && <ComponentLoader />}
              {models.map(v => {
            return <VariationsBox key={v.uuid} className={cx(v.mainImage !== null ? cssBoxImage : cssBoxText, uuid === v.uuid && cssBoxActive, !v.totalQty && cssBoxDisabled)} onClick={() => {
              if (uuid !== v.uuid) {
                setIsFetchingVariation(true);
                void router.replace(`${ROUTES.product}/${v.alias}`, undefined, {
                  scroll: true
                });
              }
            }}>
                    <VariationsBoxInner>
                      <EntityImage imagePath={v.mainImage || undefined} width={40} height={40} />
                      <Typography variant={"p12"}>{v.name}</Typography>
                    </VariationsBoxInner>
                  </VariationsBox>;
          })}
            </VariationsBoxList>
          </>}
      </StyledVariations>
    </>;
});
Variations.displayName = "Variations";
export { Variations };